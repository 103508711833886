var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ze64hJZ8hDPYHAj7JuhmF"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

import { getRuntimeConfig } from "./src/utils/runtimeConfig"

const {
  SENTRY_DSN,
  SENTRY_IS_ENABLED,
  ENVIRONMENT,
  MANDATOR_NUMBER,
  RELEASE_VERSION,
} = getRuntimeConfig()

const errorTypes = ["[GQL]", "[Network]", "[LocalStorageError]"]

function shouldSkipSentry(message) {
  const shouldSkip = !!(
    message &&
    message.startsWith &&
    errorTypes.find((errorType) => message.startsWith(errorType, 0))
  )

  return shouldSkip
}

// To disable Sentry completely, depending on environment, call Sentry.init conditionally.
if (SENTRY_IS_ENABLED) {
  Sentry.init({
    dsn: SENTRY_DSN,

    // Specifies whether this SDK should send events to Sentry. Defaults to true.
    // Setting this to enabled: false doesn't prevent all overhead from Sentry instrumentation.
    enabled: SENTRY_IS_ENABLED,

    environment: ENVIRONMENT,
    release: RELEASE_VERSION,
    // captures transactions for performance monitoring.
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    includeLocalVariables: true,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,

    initialScope: {
      tags: { brand: MANDATOR_NUMBER === "100" ? "questico" : "viversum" },
    },

    onFatalError: (e) => {
      console.error("Sentry Fatal:", e)
    },

    beforeSend: (event, hint) => {
      const { event_id: eventId, message } = event
      const { originalException } = hint
      const errorMessageFallback =
        typeof originalException === "string"
          ? originalException
          : // @ts-ignore
            originalException.message
      const body = {
        eventId,
        error: message || errorMessageFallback,
      }

      // log all errors to gcp
      window
        .fetch("/api/log", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
        .catch((error) => console.error(`Failed to log error: ${error}`))

      if (shouldSkipSentry(message)) {
        // don't send adviqo gql errors and local storage errors to sentry
        return null
      }

      return event
    },
  })
}
